import React from 'react';

const DisclaimerFooter = () => (
  <>
    <p className="mt-4 mb-2">
      {new Date().getFullYear()} © Lead1.digital. Made with{' '}
      <i className="mdi mdi-heart text-danger"></i> &amp;{' '}
      <i className="mdi mdi-heart text-danger"></i>
    </p>
    <p className="mt-1">
      <a
        href="https://www.iubenda.com/privacy-policy/45512371/legal"
        target="_blank"
        rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a
        href="https://www.iubenda.com/privacy-policy/45512371/cookie-policy"
        target="_blank"
        rel="noopener noreferrer">
        Cookie Policy
      </a>
    </p>
  </>
);

export default DisclaimerFooter;
