import React from 'react';
import { BasicConfig, Utils as QbUtils } from 'react-awesome-query-builder';
import {
  ViewButton,
  EditButton,
  TestQueryButton,
} from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getLabels,
  getSort,
} from '../Common/DataTable/tableHelpers';

const createTableColumns = (
  messageTemplates,
  customers,
  campaigns,
  leadQueryConfig,
  openViewModal,
  openEditModal,
  openTestQueryModal,
) => {
  const queryBuilderConfig = { ...BasicConfig, fields: leadQueryConfig };
  return [
    {
      title: 'Name',
      name: 'name',
      width: 150,
    },
    {
      title: 'Sector',
      name: 'sector',
      width: 150,
      hidden: true,
    },
    {
      title: 'Customer',
      name: 'customerId',
      formatterComponent: ({ value }) => getLabel(value, customers),
      sorting: (a, b) =>
        getSort(getLabel(a, customers), getLabel(b, customers)),
      filter: (value, filter) =>
        getFilter(getLabel(value, customers), filter.value),
    },
    {
      title: 'Campaigns',
      name: 'campaignIds',
      formatterComponent: ({ value }) => getLabels(value, campaigns),
      sorting: (a, b) =>
        getSort(getLabels(a, campaigns), getLabels(b, campaigns)),
      filter: (value, filter) =>
        getFilter(getLabels(value, campaigns), filter.value),
    },
    {
      title: 'Qualified?',
      name: 'qualified',
      width: 120,
      formatterComponent: ({ value }) =>
        value === 'yes' ? (
          <span className="badge badge-info font-size-12">YES</span>
        ) : (
          <span className="badge badge-dark font-size-12">NO</span>
        ),
    },
    {
      title: 'Message on entry',
      name: 'messageTemplateId',
      formatterComponent: ({ value }) => getLabel(value, messageTemplates),
      sorting: (a, b) =>
        getSort(getLabel(a, messageTemplates), getLabel(b, messageTemplates)),
      filter: (value, filter) =>
        getFilter(getLabel(value, messageTemplates), filter.value),
    },
    {
      title: 'Priority',
      name: 'priority',
      width: 100,
    },
    {
      title: 'Active',
      name: 'active',
      width: 100,
      formatterComponent: ({ value }) =>
        value ? (
          <span className="badge badge-success font-size-12">YES</span>
        ) : (
          <span className="badge badge-danger font-size-12">NO</span>
        ),
      filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
      sorting: (a, b) => getSort(a ? 'yes' : 'no', b ? 'yes' : 'no'),
    },
    {
      title: 'Query',
      name: 'query',
      formatterComponent: ({ value }) => {
        if (value === '') return '';
        const tree = QbUtils.checkTree(
          QbUtils.loadTree(value),
          queryBuilderConfig,
        );
        return JSON.stringify(QbUtils.sqlFormat(tree, queryBuilderConfig));
      },
      filter: 'disabled',
      sorting: 'disabled',
      hidden: true,
    },
    {
      title: 'Qualify Cost',
      name: 'costNegativeClosure',
      formatterComponent: ({ value }) => `€ ${value}`,
      hidden: true,
    },
    {
      title: 'Action',
      name: 'action',
      width: 160,
      align: 'center',
      formatterComponent: ({ row }) => (
        <ul className="list-inline font-size-20 contact-links mb-0">
          <ViewButton row={row} onPress={openViewModal} />
          <EditButton row={row} onPress={openEditModal} />
          {row.query !== '' && (
            <TestQueryButton row={row} onPress={openTestQueryModal} />
          )}
        </ul>
      ),
      filter: 'disabled',
      sorting: 'disabled',
    },
  ];
};

export default createTableColumns;
