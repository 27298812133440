import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import packageInfo from '../../../package.json';

const Footer = (props) => {
  const location = useLocation();

  return (
    <React.Fragment>
      {location.pathname !== '/calls/manage' && (
        <footer className="footer">
          <Container fluid={true}>
            <Row>
              <Col md={6}>
                {new Date().getFullYear()} © Lead1.digital -{' '}
                <a
                  href="https://www.iubenda.com/privacy-policy/45512371/legal"
                  target="_blank"
                  rel="noopener noreferrer">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://www.iubenda.com/privacy-policy/45512371/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer">
                  Cookie Policy
                </a>
              </Col>
              <Col md={6}>
                <div className="text-sm-right d-none d-sm-block">
                  Design & Develop with{' '}
                  <i className="mdi mdi-heart text-danger"></i> by Lead1.digital
                  - v{packageInfo.version}
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </React.Fragment>
  );
};

export default Footer;
